.App {
  height: 100%;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  padding: 0 12px;
  max-width: 1000px
}

.PinContainer {
  display: grid;
  justify-content: center;
}

.error {
  color: orange;
}

.title {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  align-content: center;

  &__back {
    background: none;
    border: none;
    font-size: 32px;
    color: white;
    justify-self: start;
    height: auto;
    align-self: center;
  }

  h1 {
    justify-self: center;
    grid-column-start: 2;
  }

  .idContainer {
    align-self: center;
  }

  .wheelId {
    justify-self: end;
    align-self: center;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__hide {
      background-color: white;
      color: white;
      border: none;
      font-size: 20px;
      margin-left: 5px;
  
      &.active {
        background: none;

        &::selection {
          background-color: #0091ff8c;
        }
      }

      &::selection {
        color: white;
      }
    }

    &__toggle {
      background: none;
      padding: 0;
      margin-left: 5px;
      border: none;

      img {
        filter: brightness(0) invert(1); 
        width: 24px;
        height: 24px;
      }
    }
  }

}

.frontPage {
  display: grid;

  iframe {
    justify-self: center;
    margin: 12px auto;
  }
}

.form {
  display: grid;
  justify-content: center;
  text-align: center;

  &__existing {
    display: flex;
    flex-direction: column;
  }

  &__divider {
    font-size: 24px;
    margin: 12px;
  }

  input {
    font-size: 24px;
    border: none;
    padding: 5px;
    box-shadow: 1px 1px 3px black;
    margin-bottom: 5px;
  }

  button {
    border: none;
    border-radius: 5px;
    background-color: #00bf16;
    color: white;
    padding: 12px;
    font-size: 20px;
    box-shadow: 1px 1px 3px black;
  }
}

.tabList {
  padding: 0px;
  border-bottom: 1px solid white;
}

.panelContainer {
  margin: 12px 0;
}

.panel {
  max-width: 1000px;
  margin: 0 auto;
}

.mainContainer {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.wheelContainer {
  grid-column-start: 2;
}

.tierContainer {
  align-self: center;
  display: grid;
  justify-content: start;
  text-align: center;
  padding-bottom: 300px;
}

.tierInfo {
  font-size: 24px;
  align-self: center;

  &__label {
    margin-right: 10px;
  }

  &__number {
    width: 36px;
    font-size: 20px;
  }
}

.mute {
  margin-top: 32px;
  height: 50px;
  width: 50px;
  background: none;
  border: none;
  justify-self: center;

  &__icon {
    height: 50px;
    width: 50px;
    filter: brightness(0) invert(1);
  }
}

.tierProgress {
  display: flex;
  margin-top: 12px;
  font-size: 64px;

  &__label {
    font-weight: bold;
  }

  .tooltip {
    width: 16px;
    height: 16px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    background-color: white;
    color: #0078B8;
  }
}

h3 {
  font-weight: 400;
}

.rules {
  li {
    margin-bottom: 10px;
  }
}

.loader {
  display: grid;
  justify-content: center;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 64px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}


// .tab {
//   background-color: #FFBA00;
// }