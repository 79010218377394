.table {
  border: 1px solid gray;
  border-spacing: 0;
  width: 100%;

  &__header {
      th {
          border-bottom: 3px solid white;
          border-right: 1px solid gray;
          background: #005C8E;
          padding: 5px;

          &:last-child {
              border-right: none;
          }
      }

      &-sortable {
        background: none;
        border: none;
        padding: none;
        color: white;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
      }
  }

  &__image {
    // max-width: 100%;
    // height: 100%;
    text-align: center;
  }

  &__row {
    background-color: #00486E;

    &.progress {
      background-color: orange;
    }

    &.complete {
      background-color: #00B81C;
    }

    &:hover {
      background-color: #00ff263d;
    }

    &.read-only:hover {
      background-color: #00486E;
    }

    td {
        border-bottom: 1px solid gray;
        border-right: 1px solid gray;
        padding: 10px 5px;

        &:last-child {
            border-right: none;
            padding: 10px 5px;
        }

        &.checkbox {
          width: 32px;
          text-align: center;
        }
    }

      .smCell {
        width: 15%;
      }

      .mdCell {
        width: 25%;
      }

      .lgCell {
        width: 60%
      }
  }
}

.filters {
  &__text {
    padding: 5px;
    font-size: 16px;
    margin: 0px 5px 5px 5px;
    border: none;
    color: black;
  }
}