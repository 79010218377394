.wheel {
    display: grid;
    justify-content: center;

    &__arrowHead {
        margin-bottom: -20px;
        text-align: center;
        width: 100%;
        color: black;
        font-size: 32px;
        z-index: 1;
    }

    &__spin {
        position: relative;
        background: none;
        border: none;
        outline: none;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        z-index: 1;

        button {
            border: none;
            border-radius: 5px;
            background-color: #00bf16;
            color: white;
            padding: 12px;
            font-size: 20px;

            &:disabled {
                background-color: gray;
            }
        }

        .info__skip {
            margin: 5px;
            background-color: red;
        }

        .info__pet {
            margin: 5px;
            background-color: rgb(150, 0, 137);
        }
    }

    &__task {
        font-weight: 700;
        font-size: 30px;
        text-align: center;
    }

    &__disclaimer {
        position: absolute;
        text-align: center;
        color: white;
        background-color: rgba(0, 0, 0, 0.713);
        padding: 12px;
        max-width: 500px;
        font-size: 28px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    .tooltip {
        margin: 0;
        padding: 5px;
        max-width: 250px;

        li {
            margin-top: 5px;
            font-weight: bold;

            &.Item {
                color: orange;
            }

            &.Quest {
                color: cyan;
            }

            &.Diary {
                color: limegreen;
            }

            &.Clue {
                color: red;
            }
        }
    }
}